import React from 'react'
import Layout from '../components/layout'
import ChildrenGrid from '../components/sections/ChildrenGrid'
import LogotherapyGrid from '../components/sections/LogotherapyGrid'
import BlogSlider from '../components/buildingBlocks/BlogSlider'
import Spacer from '../components/uielements/Spacer'
import Ergotherapy from '../components/sections/Ergotherapy'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'
import { useTranslation } from 'react-i18next'
import Evaluation from '../components/sections/Evaluation'

const ChildrenPage = () => {
    const { t } = useTranslation()
    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('children-title')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('children-title')} />
                <ChildrenGrid />
                <Spacer top="4" />
                <LogotherapyGrid />
                <Spacer top="4" />
                <Ergotherapy />
                <Spacer top="4" />
                <Evaluation />
                <Spacer top="4" />
                <BlogSlider />
            </Wrapper>
        </Layout>
    )
}

export default ChildrenPage
