import React from 'react'
import BgImage from '../../../images/akiosologisi.jpg'
import InnerTitle from '../../uielements/InnerTitle'
import styled from 'styled-components'
import Spacer from '../../uielements/Spacer'
import Button from '../../uielements/Button'
import Text from '../../uielements/Text'
import Underline from '../../uielements/Underline'
import BlueWhiteCircle from '../../uielements/Circle/blueWhite.svg'
import FlexWrapper from '../../uielements/flexWrapper'
import { screenSizes } from '../../../lib/constants'
import { useTranslation } from 'react-i18next'

const ParentingWrapper = styled.div`
  background: url('${BgImage}') center center no-repeat;
  background-size: cover;
  padding: 40px 80px;
  position: relative;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  
  @media only screen and (max-width: ${screenSizes.md}px){
    padding: 30px 15px 50px 15px;
    background: url('${BgImage}') right center no-repeat;
    background-size: cover;
    
    .overlay {
      position: absolute;
      background: rgba(255,255,255,0.7);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  
  span{
    z-index: 9;
  }
`

const Rectangle = styled.div`
    background-color: ${({ theme }) => theme.purple};
    width: 200px;
    height: 200px;
    border-radius: 500px;
    position: absolute;
    z-index: 9;

    @media only screen and (max-width: ${screenSizes.md}px) {
        width: 80px;
        height: 80px;
        display: none;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    z-index: 9;
`

const BlockTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
    z-index: 9;

    @media only screen and (max-width: ${screenSizes.md}px) {
        align-items: center;
    }
`

const Circle = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background: url('${BlueWhiteCircle}');
  z-index: 2;
  position: relative;
  background-size: 40px;
  background-repeat: no-repeat;
  margin-left: 80px;
  
  @media only screen and (max-width: ${screenSizes.md}px){
    display: none;
  }
`

const MainTitle = styled.h2`
    max-width: 50px;

    @media only screen and (max-width: ${screenSizes.md}px) {
        font-size: 1em !important;
    }
`

const BlockTitle = styled.div`
    z-index: 1;
    flex-direction: column;
    justify-content: flex-start;
`

const ReadMore = styled.div`
    z-index: 9;
`

const Evaluation = props => {
    const { t } = useTranslation()

    return (
        <div>
            <InnerTitle>{t('evaluation')}</InnerTitle>
            <Spacer top="2" bottom="2" />
            <ParentingWrapper>
                <div className="overlay">&nbsp;</div>
                <Spacer top="3" bottom="3" />

                <ContentWrapper>
                    <Rectangle />
                    <BlockTitleWrapper>
                        <div className="hidden-sm">
                            <Circle />
                        </div>
                        <Spacer top="2" bottom="2" left="1" right="1" />
                        <BlockTitle>
                            <Underline />
                            <MainTitle>
                                <Text
                                    textColor={({ theme }) => theme.blue}
                                    tt="capitalize"
                                    fw="bold"
                                    fs="2.4"
                                >
                                    {t('evaluation-dpt')}
                                </Text>
                            </MainTitle>
                        </BlockTitle>
                    </BlockTitleWrapper>
                </ContentWrapper>

                <ReadMore>
                    <FlexWrapper justifyContent="center" alignItems="center">
                        <Button link="/evaluation" arrow="blue">
                            {' '}
                            {t('read-more')}{' '}
                        </Button>
                    </FlexWrapper>
                </ReadMore>
            </ParentingWrapper>
        </div>
    )
}

export default Evaluation
