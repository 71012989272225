import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import ContentRectangle from '../../uielements/ContentRectangle'
import PropTypes from 'prop-types'
import InnerTitle from '../../uielements/InnerTitle'
import { useTranslation } from 'react-i18next'
import Flex from '../../uielements/Flex'
import Spacer from '../../uielements/Spacer'
import FlexWrapper from '../../uielements/flexWrapper'

const MyLogotherapyGrid = ({ data, props }) => {
    const { t, i18n } = useTranslation()

    return (
        <>
            {props.postType === 'children' && (
                <InnerTitle>{t('logotherapy-children')}</InnerTitle>
            )}
            {props.postType === 'adults' && (
                <InnerTitle>{t('logotherapy-adults')}</InnerTitle>
            )}
            <Spacer top="2" />
            <FlexWrapper xl={{ margin: '0 -20px 0 -10px' }}>
                {data.children.nodes.map(page => {
                    return (
                        (props.displayInHome
                            ? page.displayInHome === 'yes'
                            : true) &&
                        page.node_locale === i18n.language &&
                        page.postType === props.postType && (
                            <Flex
                                flexBasis="33%"
                                key={page.id}
                                sm={{ flexBasis: '100%' }}
                                md={{ flexBasis: '50%' }}
                                lg={{ flexBasis: '50%' }}
                            >
                                <Spacer
                                    left="1"
                                    right="1"
                                    bottom="2"
                                    sm={{ right: '0', bottom: '2' }}
                                >
                                    <Link
                                        title={page.title}
                                        to={
                                            '/' +
                                            props.postType +
                                            '/' +
                                            page.slug
                                        }
                                    >
                                        <ContentRectangle
                                            imgObj={page.featuredImage}
                                        >
                                            {page.title}
                                        </ContentRectangle>
                                    </Link>
                                </Spacer>
                            </Flex>
                        )
                    )
                })}
            </FlexWrapper>
        </>
    )
}

const LogotherapyGrid = props => {
    return (
        <StaticQuery
            query={graphql`
                query fetchChildrenLogotherapy {
                    children: allContentfulPage(
                        filter: {
                            main: { eq: "yes" }
                            logotherapy: { eq: "ΝΑΙ" }
                        }
                    ) {
                        nodes {
                            slug
                            title
                            id
                            node_locale
                            main
                            postType
                            category {
                                slug
                                title
                                excerpt {
                                    raw
                                }
                            }
                            featuredImage {
                                url
                                title
                                gatsbyImageData(
                                    quality: 75
                                    jpegProgressive: true
                                    sizes: "1920, 960, 480"
                                    cropFocus: CENTER
                                )
                            }
                        }
                        totalCount
                    }
                }
            `}
            render={data => <MyLogotherapyGrid data={data} props={props} />}
        ></StaticQuery>
    )
}

LogotherapyGrid.defaultProps = {
    displayInHome: false,
    postType: 'children',
}

LogotherapyGrid.propTypes = {
    displayInHome: PropTypes.bool,
    postType: PropTypes.string,
}

export default LogotherapyGrid
